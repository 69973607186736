//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from "vuex";
import ServiceAnalysisCard from "./ServiceAnalysisCard";
import AnalystDetail from "./AnalystDetail";
import { queryGetAllGeneticAnalystServices } from "@/common/lib/polkadot-provider/query/genetic-analysts-services";
import { queryGeneticAnalystByAccountId, queryGeneticAnalystQualificationsByHashId } from "@debionetwork/polkadot-provider";
import ImportantDialog from "./Information.vue";
import Web3 from "web3";
export default {
  name: "SelectServiceAnalyst",
  data: () => ({
    stepperItems: [{
      number: 1,
      title: "Select Genetic Data",
      active: false
    }, {
      number: 2,
      title: "Select Service & Analyst",
      active: true
    }, {
      number: 3,
      title: "Checkout and Payment",
      active: false
    }, {
      number: 4,
      title: "Summary/Instruction",
      active: false
    }],
    showAnalystDetail: false,
    serviceList: [],
    selectedService: null,
    selectedAnalystExperiences: null,
    isLoading: false,
    showInformationDialog: false,
    isShowLoading: false
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      selectedGeneticData: state => state.geneticData.selectedData
    })
  },
  components: {
    ServiceAnalysisCard,
    AnalystDetail,
    ImportantDialog
  },
  async mounted() {
    if (!this.selectedGeneticData) {
      this.$router.push({
        name: "customer-request-analysis"
      });
    }
    await this.getGeneticAnalystService();
    this.showInformationDialog = true;
  },
  methods: {
    ...mapMutations({
      setSelectedAnalysisService: "geneticData/SET_SELECTED_SERVICE"
    }),
    async getGeneticAnalystService() {
      this.isLoading = true;
      const geneticAnalystService = await queryGetAllGeneticAnalystServices(this.api);
      const handleDescription = async description => {
        try {
          if (typeof description === "string" && description.startsWith("0x")) {
            return Web3.utils.hexToUtf8(description);
          }
          const regex = /^(https:\/\/ipfs.debio.network\/ipfs\/)/;
          if (regex.test(description)) {
            const response = await fetch(description);
            if (response.ok) {
              const text = await response.text();
              return text;
            } else {
              return "Description fetch failed";
            }
          }
          return description;
        } catch (error) {
          return "Description processing error";
        }
      };
      for (let i = 0; i < geneticAnalystService.length; i++) {
        let {
          id: serviceId,
          ownerId: analystId,
          info: {
            name: serviceName,
            pricesByCurrency: priceDetail,
            expectedDuration: {
              duration,
              durationType
            },
            description,
            testResultSample
          }
        } = geneticAnalystService[i][1].toHuman();
        const analystsInfo = await queryGeneticAnalystByAccountId(this.api, analystId);
        if (analystsInfo.verificationStatus === "Verified") {
          description = await handleDescription(description);
          const service = {
            serviceId,
            analystId,
            serviceName,
            priceDetail,
            duration,
            durationType,
            description,
            testResultSample,
            analystsInfo
          };
          this.serviceList.push(service);
        }
      }
      this.isLoading = false;
    },
    handleBack() {
      this.$router.push({
        name: "customer-request-analysis"
      });
    },
    async showDetail(val) {
      this.selectedService = val;
      this.setSelectedAnalysisService(val);
      const qualifications = val.analystsInfo.qualifications[0];
      await this.getExperience(qualifications);
    },
    async getExperience(id) {
      let experiences;
      if (id) {
        experiences = await queryGeneticAnalystQualificationsByHashId(this.api, id);
        this.selectedAnalystExperiences = experiences.info.experience;
      } else {
        this.selectedAnalystExperiences = ["-"];
      }
      await this.$refs.analystDetail.getTxWeight();
      this.showAnalystDetail = true;
    },
    closeDetailDialog() {
      this.showAnalystDetail = false;
    },
    goToDashboardPage() {
      this.$router.push({
        name: "dashboard"
      });
    }
  }
};