import "core-js/modules/es.typed-array.from.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/esnext.typed-array.find-last.js";
import "core-js/modules/esnext.typed-array.find-last-index.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import Kilt from "@kiltprotocol/sdk-js";
import CryptoJS from "crypto-js";
import cryptWorker from "@/common/lib/ipfs/crypt-worker";
import { u8aToHex } from "@polkadot/util";
import { queryLastGeneticAnalysisOrderByCustomerId, queryGeneticAnalystByAccountId } from "@debionetwork/polkadot-provider";
import { createGeneticAnalysisOrderFee, createGeneticAnalysisOrder } from "@/common/lib/polkadot-provider/command/genetic-analysis-orders";
import { queryLastGeneticAnalysisOrderByCustomer } from "@/common/lib/polkadot-provider/query/genetic-analysis-orders";
import { downloadFile, uploadFile, getFileUrl } from "@/common/lib/pinata-proxy";
// import SpinnerLoader from "@bit/joshk.vue-spinners-css.spinner-loader"
import UploadingDialog from "@/common/components/Dialog/UploadingDialog";
import { formatUSDTE } from "@/common/lib/price-format.js";
import store from "@/store";
import Web3 from "web3";
export default {
  name: "AnalystDetail",
  data: () => ({
    price: null,
    publicKey: "",
    privateKey: "",
    fileType: "",
    fileName: "",
    links: [],
    files: [],
    file: "",
    geneticLink: "",
    isLoading: false,
    txWeight: "Calculating...",
    showAlert: false,
    errorAlert: false,
    totalChunks: 0,
    currentChunkIndex: 0,
    isFailed: false
  }),
  components: {
    UploadingDialog
  },
  props: {
    show: Boolean,
    experiences: Array
  },
  async created() {
    if (this.mnemonicData) {
      await this.getCustomerPublicKey();
      await this.getTxWeight();
    }
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      walletBalance: state => state.substrate.walletBalance,
      mnemonicData: state => state.substrate.mnemonicData,
      lastEventData: state => state.substrate.lastEventData,
      selectedGeneticData: state => state.geneticData.selectedData,
      service: state => state.geneticData.selectedAnalysisSerivice,
      polkadotWallet: state => state.substrate.polkadotWallet
    }),
    computeAvatar() {
      const profile = this.service.analystsInfo.info.profileImage;
      return profile ? profile : require("@/assets/defaultAvatar.svg");
    },
    computePrice() {
      return "".concat(this.formatBalance(this.service.priceDetail[0].totalPrice, formatUSDTE(this.service.priceDetail[0].currency)), " ").concat(formatUSDTE(this.service.priceDetail[0].currency));
    }
  },
  watch: {
    lastEventData(e) {
      if (e !== null) {
        const dataEvent = JSON.parse(e.data.toString());
        if (e.method === "GeneticAnalysisOrderCreated") {
          if (dataEvent[0].customerId === this.wallet.address) {
            this.isLoading = false;
            this.toCheckoutPage();
          }
        }
      }
    }
  },
  methods: {
    async getLastOrderStatus() {
      let lastOrder;
      try {
        lastOrder = await queryLastGeneticAnalysisOrderByCustomerId(this.api, this.wallet.address);
        return lastOrder.status;
      } catch (error) {
        lastOrder = null;
        return lastOrder;
      }
    },
    async getTxWeight() {
      const txWeight = await createGeneticAnalysisOrderFee(this.api, this.wallet, this.selectedGeneticData.id, this.service.serviceId, 0, this.publicKey, this.geneticLink);
      this.txWeight = "".concat(Number(Web3.utils.fromWei(String(txWeight.partialFee), "ether")).toFixed(4), " DBIO");
    },
    async getCustomerPublicKey() {
      const identity = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.publicKey = u8aToHex(identity.boxKeyPair.publicKey);
      this.privateKey = u8aToHex(identity.boxKeyPair.secretKey);
    },
    async getAnalystPublicKey() {
      const id = this.service.analystId;
      const analystDetail = await queryGeneticAnalystByAccountId(this.api, id);
      const analystPublicKey = analystDetail.info.boxPublicKey;
      return analystPublicKey;
    },
    closeDialog() {
      this.$emit("close");
    },
    async handleDownloadReport() {
      window.open(this.service.testResultSample);
    },
    async onSelect() {
      const status = await this.getLastOrderStatus();
      if (status === "Unpaid") {
        this.showAlert = true;
        return;
      }
      const txWeight = Number(this.txWeight.split(" ")[0]);
      if (this.walletBalance < txWeight) {
        this.errorAlert = true;
        this.closeDialog();
        return;
      }
      this.isLoading = true;
      this.geneticLink = "";
      this.links = [];
      const links = JSON.parse(this.selectedGeneticData.reportLink);
      let uploadedLinks = 0;
      try {
        for (let i = 0; i < links.length; i++) {
          this.totalChunks = links.length;
          this.currentChunkIndex = i;
          const {
            name,
            type,
            data
          } = await downloadFile(links[i], true);
          const fileType = type;
          const fileName = name;
          let {
            box,
            nonce
          } = data.data;
          box = Object.values(box); // Convert from object to Array
          nonce = Object.values(nonce); // Convert from object to Array

          const toDecrypt = {
            box: Uint8Array.from(box),
            nonce: Uint8Array.from(nonce)
          };
          const decryptedObject = await Kilt.Utils.Crypto.decryptAsymmetric(toDecrypt, this.publicKey, this.privateKey);
          const unit8Arr = new Uint8Array(decryptedObject);
          const blob = new Blob([unit8Arr], {
            type: fileType
          });
          const file = new File([blob], fileName);
          const encryptedFile = await this.encrypt({
            text: file,
            fileType: fileType,
            fileSize: file.size,
            fileName: file.name
          });
          try {
            await this.upload({
              encryptedFileChunks: encryptedFile.chunks,
              fileName: encryptedFile.fileName,
              fileType: fileType,
              fileSize: encryptedFile.fileSize
            });
            uploadedLinks++; // Increment the uploaded counter
          } catch (error) {
            this.uploadFailed = true;
            this.currentFile = file;
            this.uploadingType = "Uploading"; // Show the dialog with retry button
          }
        }
      } catch (error) {
        this.isFailed = true;
        this.isLoading = false;
      }
      if (uploadedLinks === links.length) {
        this.geneticLink = JSON.stringify(this.links);
        if (this.geneticLink) {
          await this.createOrder();
        }
      }
    },
    setupFileReader(file) {
      return new Promise((res, rej) => {
        const context = this;
        const fr = new FileReader();
        fr.onload = async function () {
          try {
            const encrypted = await context.encrypt({
              text: fr.result,
              fileType: file.type,
              fileSize: file.size,
              fileName: file.name
            });
            const {
              chunks,
              fileName,
              fileType,
              fileSize
            } = encrypted;
            const dataFile = {
              title: "title",
              description: "description",
              file,
              chunks,
              fileSize,
              fileName,
              fileType,
              createdAt: new Date().getTime()
            };
            res(dataFile);
          } catch (e) {}
        };
        fr.onerror = rej;
        fr.readAsArrayBuffer(file);
      });
    },
    async encrypt(_ref) {
      let {
        text,
        fileType,
        fileName,
        fileSize
      } = _ref;
      const analystPublicKey = await this.getAnalystPublicKey();
      const context = this;
      const arrChunks = [];
      let chunksAmount;
      const pair = {
        secretKey: this.privateKey,
        publicKey: analystPublicKey
      };
      return await new Promise((res, rej) => {
        try {
          cryptWorker.workerEncryptFile.postMessage({
            pair,
            text,
            fileType
          });
          cryptWorker.workerEncryptFile.onmessage = async event => {
            if (event.data.chunksAmount) {
              chunksAmount = event.data.chunksAmount;
              return;
            }
            arrChunks.push(event.data);
            context.encryptProgress = arrChunks.length / chunksAmount * 100;
            if (arrChunks.length === chunksAmount) {
              res({
                fileName,
                fileType,
                fileSize,
                chunks: arrChunks
              });
            }
          };
        } catch (e) {
          rej(new Error(e.message));
        }
      });
    },
    async upload(_ref2) {
      let {
        encryptedFileChunks,
        fileName,
        fileType,
        fileSize
      } = _ref2;
      for (let i = 0; i < encryptedFileChunks.length; i++) {
        store.dispatch("geneticData/getLoadingProgress", {
          upload: 0
        });
        let data = ["{\"seed\":".concat(encryptedFileChunks[i].seed, ",\"data\":{\"nonce\":[").concat(encryptedFileChunks[i].data.nonce, "],\"box\":[").concat(encryptedFileChunks[i].data.box)];
        data.push("]}}");
        const blob = new Blob(data, {
          type: fileType
        });
        try {
          const result = await uploadFile({
            title: fileName,
            type: fileType,
            size: fileSize,
            file: blob
          });
          const link = await getFileUrl(result.IpfsHash);
          this.links.push(link);
        } catch (error) {
          this.isFailed = true; // Set isFailed to true if the upload fails for any chunk
        }
      }
    },

    formatBalance(balance, currency) {
      let unit;
      currency === "USDT" || currency === "USDT.e" ? unit = "mwei" : unit = "ether";
      const formatedBalance = Web3.utils.fromWei(String(balance.replaceAll(",", "")), unit);
      return Number(formatedBalance).toLocaleString("en-US");
    },
    async getAssetId(currency) {
      let assetId = 0;
      const wallet = this.polkadotWallet.find(wallet => {
        var _wallet$currency;
        return (wallet === null || wallet === void 0 ? void 0 : (_wallet$currency = wallet.currency) === null || _wallet$currency === void 0 ? void 0 : _wallet$currency.toUpperCase()) === (currency === null || currency === void 0 ? void 0 : currency.toUpperCase());
      });
      assetId = wallet.id;
      return assetId;
    },
    async createOrder() {
      const priceIndex = 0;
      const currency = this.service.priceDetail[0].currency;
      const assetId = await this.getAssetId(currency === "USDTE" ? "USDT.e" : currency);
      await createGeneticAnalysisOrder(this.api, this.wallet, this.selectedGeneticData.id, this.service.serviceId, priceIndex, this.publicKey, this.geneticLink, assetId);
    },
    async toCheckoutPage() {
      const lastOrder = await queryLastGeneticAnalysisOrderByCustomer(this.api, this.wallet.address);
      this.$router.push({
        name: "customer-request-analysis-payment",
        params: {
          id: lastOrder
        }
      });
    },
    toPaymentHistory() {
      this.$router.push({
        name: "customer-payment-history"
      });
    }
  }
};